import React from "react";
import { Link, useMatch } from "react-router-dom";
import { NavButtonProps } from "../@types/Interfaces";

const NavButton = (props: NavButtonProps) => {
  // props
  const { href, children, title } = props;

  const isMatched = useMatch(href);

  return (
    <li className={`sidebar__menu__element ${isMatched ? "activeNavBtn" : ""}`}>
      <Link to={href} style={{ color: "inherit" }}>
        {/* assign dynamic  className   to link */}
        {{ ...children, props: { ...children.props, className: `sidebar__menu__element__icon ${isMatched ? "activeNavBtn" : ""}` } }}
        {title}
      </Link>
    </li>
  );
};

export default NavButton;
