import { createApi } from "@reduxjs/toolkit/query/react";

import { queryBuilder } from "../../../util/queryBuilder";
import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import {
  createReq,
  createRes,
  deleteReq,
  deleteRes,
  listReq,
  listRes,
  myListReq,
  myListRes,
  profileReq,
  profileRes,
  profileStatsReq,
  profileStatsRes,
  profileVideosReq,
  profileVideosRes,
  sharedReq,
  sharedRes,
  viewedReq,
  viewedRes,
} from "./types";

export const postApi = createApi({
  reducerPath: "post-api",
  baseQuery: getBaseQuery("/media/post") as MyBaseQueryFn,
  tagTypes: ["Post"],
  endpoints: builder => ({
    violations: builder.query({
      query: body => ({
        url: `/violations`,
        method: "GET",
      }),
    }),
    list: builder.query<listRes, listReq>({
      query: body => ({
        url: `/list${queryBuilder(body.query)}`,
        method: "GET",
      }),
    }),
    myList: builder.query<myListRes, myListReq>({
      query: body => ({
        url: `/profile/videos${queryBuilder(body.query)}`,
        method: "GET",
      }),
    }),
    reportedVideosList: builder.query<myListRes, myListReq>({
      query: body => ({
        url: `/report/list`,
        method: "GET",
      }),
    }),
    reportedVideoInfo: builder.query<any, any>({
      query: body => ({
        url: `/report/video/${body.id}`,
        method: "GET",
      }),
    }),
    profile: builder.query<profileRes, profileReq>({
      query: body => ({
        url: `/profile/${body.id}/stats`,
        method: "GET",
      }),
    }),
    profileVideos: builder.query<profileVideosRes, profileVideosReq>({
      query: body => ({
        url: `/profile/${body.id}/videos${queryBuilder(body.query)}`,
        method: "GET",
      }),
    }),
    profileStats: builder.query<profileStatsRes, profileStatsReq>({
      query: body => ({
        url: `/profile/${body.id}/stats`,
        method: "GET",
      }),
    }),
    create: builder.mutation<createRes, createReq>({
      query: body => ({
        url: "/create",
        body,
        method: "POST",
      }),
    }),
    delete: builder.mutation<deleteRes, deleteReq>({
      query: body => ({
        url: `/delete/${body.id}`,
        body,
        method: "DELETE",
      }),
    }),
    viewed: builder.mutation<viewedRes, viewedReq>({
      query: body => ({
        url: `/viewed/${body.id}`,
        method: "POST",
      }),
    }),
    shared: builder.mutation<sharedRes, sharedReq>({
      query: body => ({
        url: `/shared/${body.id}`,
        method: "POST",
      }),
    }),
  }),
});
