import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import storage from "redux-persist/lib/storage";

//apis
import { authApi } from "./apis/auth";
import { likeApi } from "./apis/like";
import { postApi } from "./apis/post";
import { commentApi } from "./apis/comment";
import { skillApi } from "./apis/skill";
import { clubApi } from "./apis/club";
import { miscellaneousApi } from "./apis/miscellaneous";

//slices
import authSlice from "./slices/auth";
import registerSlice from "./slices/register";
import uploadSlice from "./slices/upload";

//commons
// import { unauthenticatedMiddleware } from './middlewares/unauthenticated';
import { RESET_STATE_ACTION_TYPE } from "./actions/resetState";
import { UsersApi } from "./apis/users";

const combinedReducers = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [UsersApi.reducerPath]: UsersApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [likeApi.reducerPath]: likeApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  [skillApi.reducerPath]: skillApi.reducer,
  [clubApi.reducerPath]: clubApi.reducer,
  [miscellaneousApi.reducerPath]: miscellaneousApi.reducer,
  auth: authSlice.reducer,
  register: registerSlice.reducer,
  upload: uploadSlice.reducer,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const reducer = persistReducer(
  {
    key: "root",
    storage,
    version: 1,
    blacklist: [
      authApi.reducerPath,
      postApi.reducerPath,
      likeApi.reducerPath,
      commentApi.reducerPath,
      skillApi.reducerPath,
      clubApi.reducerPath,
      miscellaneousApi.reducerPath,
    ],
  },
  rootReducer
);

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      //  unauthenticatedMiddleware,
      authApi.middleware,
      postApi.middleware,
      likeApi.middleware,
      commentApi.middleware,
      skillApi.middleware,
      clubApi.middleware,
      miscellaneousApi.middleware,
    ]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
