import React from "react";
import { Input, Avatar } from "antd";
import { Menu, Dropdown } from "antd";

import { HeaderProps } from "../@types/Interfaces";
import { SideBarToogleButton } from "../components/SideBarToogleButton";
import { Person } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const { Search } = Input;

function Header(props: HeaderProps) {
  // props
  const { toggle, setToggle } = props;

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(() => {
      navigate("/login");
    });
  };

  // actions
  const onSearch = value => console.log(value);

  // menu
  const menu = (
    //@ts-ignore
    <Menu
      items={[
        {
          label: (
            <Link to="/account">
              <a>Account</a>
            </Link>
          ),
        },
        {
          label: (
            <Link to="/account/settings">
              <a>Settings</a>
            </Link>
          ),
        },
        {
          label: <a onClick={handleLogout}>Logout</a>,
        },
      ]}
    />
  );

  // render component
  return (
    <header className={toggle ? "full__header  header" : "header"}>
      <SideBarToogleButton isToggled={toggle!} handleSideBarToggle={() => setToggle(!toggle)} />
      <div className="header__searchInput">
        <Search placeholder="search for videos , users, ..." onSearch={onSearch} enterButton />
      </div>
      <div className="header_grow"></div>
      <div className=" header__avatar">
        <Dropdown overlay={menu} placement="bottomLeft" arrow trigger={["click"]}>
          <Avatar shape="square" size="default" icon={<Person />} />
        </Dropdown>
      </div>
    </header>
  );
}

export default Header;
