import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPost {
    thumbnail: string;
    video: string;
    caption?: string;
    skills: string[];
}
interface IInitialState {
    post: IPost | null;
    progress: number | null;
}

const uploadSlice = createSlice({
    name: 'auth',
    initialState: {
        post: null,
        progress: null,
    } as IInitialState,
    reducers: {
        setPost: (state, { payload }: PayloadAction<IPost>) => {
            state.post = payload;
        },
        setProgress: (state, { payload }: PayloadAction<number>) => {
            state.progress = payload;
        },
        setDone: (state) => {
            state.post = null;
            state.progress = null;
        },
    },
});

export default uploadSlice;
export const { setPost, setDone, setProgress } = uploadSlice.actions;
