import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { LayoutProps } from "../@types/Interfaces";

const Layout = (props: LayoutProps) => {
  // props
  const { children } = props;

  // hooks

  const [toggle, setToggle] = useState(true);

  // render component
  return (
    <div className="Student__area">
      <Header toggle={toggle} setToggle={setToggle} />
      {<Sidebar toggle={toggle} />}
      <div className={toggle ? " full__content content" : "content"}>
        {/* // !  be careful if you did not pass any children to component it will pass outlet */}
        {children ? children : <Outlet />}
      </div>
    </div>
  );
};

export default Layout;
