import React from "react";
import { Route, Routes } from "react-router-dom";
import { RootAppProps } from "./@types/Interfaces";
import LoadingSpinner from "./components/LoadingSpinner";

import Layout from "./layout";
import ProtectedPage from "./modules/Auth/ProtectedPage";

import Comments from "./pages/comments";
import Skills from "./pages/skills";

// pages

const Settings = React.lazy(() => import("./pages/account/Settings"));
const Users = React.lazy(() => import("./pages/Users"));
const Videos = React.lazy(() => import("./pages/Videos"));
const ErrorPage = React.lazy(() => import("./pages/_errorPage"));
const Account = React.lazy(() => import("./pages/account"));
const Login = React.lazy(() => import("./pages/Login"));
const Report = React.lazy(() => import("./pages/Reports"));
const Clubs = React.lazy(() => import("./pages/Clubs"));
//  App Root

function App(props: RootAppProps) {
  // props
  const {} = props;

  // loading spinner
  const loadingSpinner = (
    <>
      <LoadingSpinner />
    </>
  );

  return (
    <div className="App">
      <React.Suspense fallback={loadingSpinner}>
        <Routes>
          {/* pages with layout */}

          <Route
            path="/"
            element={
              <ProtectedPage>
                <Layout />
              </ProtectedPage>
            }
          >
            <Route path="/users" element={<Users />} />
            <Route index element={<Videos />} />
            <Route path="/comments" element={<Comments />} />
            {/* <Route path="/statistc" element={<Statistc />} /> */}
            <Route path="/account/settings" element={<Settings />} />
            <Route path="/account" element={<Account />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/reports" element={<Report />} />
            <Route path="/clubs" element={<Clubs />} />
          </Route>
          {/* error page */}
          <Route path="*" element={<ErrorPage />} />

          {/* login page */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;
