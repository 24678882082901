import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { RootState } from "..";
import { API_URL } from "../../constant/api";
import { handleRefreshToken } from "../../util/handleRefreshToken";
import { resetStateAction } from "../actions/resetState";
import { setCurrentAuthTokens } from "../slices/auth";

import prepareHeaders from "./prepareHeaders";

const getBaseQuery = (endpoint: string) => {
  const baseQuery = fetchBaseQuery({ baseUrl: `${API_URL}${endpoint}`, prepareHeaders });
  const mutex = new Mutex();

  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const { refreshToken } = (api.getState() as RootState).auth;
          if (refreshToken) {
            const tokens = await handleRefreshToken(refreshToken);
            if (tokens.accessToken && tokens.refreshToken) {
              api.dispatch(setCurrentAuthTokens(tokens));
              // retry the initial query
              result = await baseQuery(args, api, extraOptions);
            } else {
              api.dispatch(resetStateAction());
            }
          } else {
            api.dispatch(resetStateAction());
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  };

  return baseQueryWithReauth;
};

export default getBaseQuery;
