import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { updateProfileReq } from '../apis/auth/types';

const registerSlice = createSlice({
    name: 'register',
    initialState: {
        type: '',
    } as updateProfileReq,
    reducers: {
        setType: (state, { payload }: PayloadAction<{ type: string }>) => {
            state.type = payload?.type;
        },
        setBasicInformations: (
            state,
            {
                payload,
            }: PayloadAction<{
                // lastname: string;
                // firstname: string;
                country: string;
                birthdate: string;
                image: string | null;
                favoritefoot: string;
            }>
        ) => {
            Object.entries(payload).forEach(([key, value]) => {
                //@ts-ignore
                state[key] = value;
            });
        },
    },
});

export default registerSlice;
export const { setBasicInformations, setType } = registerSlice.actions;
