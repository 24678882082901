import { Avatar, Button, Card, Comment, Divider, PageHeader, Pagination } from "antd";
import React, { useEffect } from "react";
import { commentApi } from "../store/apis/comment";

const Comments = () => {
  const [fetchComments, { data, isLoading, error }] = commentApi.useLazyAllCommentsListQuery();
  const [DisableComment, { data: dataDelete, isLoading: disLoadingDelete }] = commentApi.useDisableCommentMutation();

  console.log(data?.data.comments);

  useEffect(() => {
    fetchComments({ query: { page: 0, size: 10 }, id: "" });
  }, []);

  return (
    <div className="comments_container">
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Comments"
        subTitle={`total Comments is ${data?.data.comments.length}`}
      />
      <Divider />

      {data?.data.comments.map(comment => {
        return (
          <Card
            style={{
              borderRadius: 10,
              marginTop: 10,
            }}
          >
            <Comment
              author={
                <a>
                  {comment.user.firstname} {comment.user.lastname}
                </a>
              }
              avatar={
                <Avatar src={comment.user.image}>
                  {comment.user.firstname} {comment.user.lastname}
                </Avatar>
              }
              content={<p>{comment.comment}</p>}
              actions={[
                <Button
                  onClick={() => {
                    DisableComment({ comment_id: comment.id }).then(() => {
                      fetchComments({ query: { page: 0, size: 10 }, id: "" });
                    });
                  }}
                >
                  disable
                </Button>,
              ]}
            />
          </Card>
        );
      })}
      <div className="videoPage__pagination">
        {data && (
          <Pagination
            pageSize={data?.data.size}
            total={data.data.totalPages * data.data.size}
            onChange={(page, pageSize) => fetchComments({ query: { size: pageSize, page: page - 1 }, id: "" })}
            defaultCurrent={0}
          />
        )}
      </div>
    </div>
  );
};

export default Comments;
