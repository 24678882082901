import { createApi } from '@reduxjs/toolkit/query/react';

import { MyBaseQueryFn } from '../apiBaseQueryFn';
import getBaseQuery from '../baseQueryWithReauth';
import { rateVideoReq, rateVideoRes } from './types';

export const miscellaneousApi = createApi({
    reducerPath: 'miscellaneous-api',
    baseQuery: getBaseQuery('/media') as MyBaseQueryFn,
    tagTypes: ['Miscellaneous'],
    endpoints: (builder) => ({
        rateVideo: builder.mutation<rateVideoRes, rateVideoReq>({
            query: (body) => ({
                url: `/rating/add/${body.videoId}`,
                body,
                method: 'POST',
            }),
        }),
    }),
});
