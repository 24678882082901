import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import { addClubReq, addClubRes, listRes, updateClubReq, updateClubRes } from "./types";

export const clubApi = createApi({
  reducerPath: "club-api",
  baseQuery: getBaseQuery("/auth/club") as MyBaseQueryFn,
  tagTypes: ["club"],
  endpoints: builder => ({
    list: builder.query<listRes, void>({
      query: () => ({
        url: `/list`,
        method: "GET",
      }),
      providesTags: [{ type: "club", id: "list" }],
    }),
    addClub: builder.mutation<addClubRes, addClubReq>({
      query: body => ({
        url: "/add",
        body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "club", id: "list" }],
    }),
    updateClub: builder.mutation<updateClubRes, updateClubReq>({
      query: body => ({
        url: "/update",
        body,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "club", id: "list" }],
    }),
  }),
});
