import { createApi } from "@reduxjs/toolkit/query/react";

import { queryBuilder } from "../../../util/queryBuilder";
import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import {
  commentsByVideoReq,
  commentsByVideoRes,
  createCommentReq,
  createCommentRes,
  deleteCommentReq,
  deleteCommentRes,
  disableCommentReq,
} from "./types";

export const commentApi = createApi({
  reducerPath: "comment-api",
  baseQuery: getBaseQuery("/media/comment") as MyBaseQueryFn,
  tagTypes: ["Comment"],
  endpoints: builder => ({
    commentsByVideo: builder.query<commentsByVideoRes, commentsByVideoReq>({
      query: body => ({
        url: `/list/${body.id}${queryBuilder({ id: body.comment_id, ...body.query })}`,
        method: "GET",
      }),
    }),
    deleteComment: builder.mutation<deleteCommentRes, deleteCommentReq>({
      query: body => ({
        url: `/delete/${body.videoId}`,
        body,
        method: "DELETE",
      }),
    }),
    createComment: builder.mutation<createCommentRes, createCommentReq>({
      query: body => ({
        url: `/add/${body.postId}`,
        body,
        method: "POST",
      }),
    }),
    disableComment: builder.mutation<createCommentRes, disableCommentReq>({
      query: body => ({
        url: `/disable/${body.comment_id}`,
        body,
        method: "PUT",
      }),
    }),
    AllCommentsList: builder.query<commentsByVideoRes, commentsByVideoReq>({
      query: body => ({
        url: `/list?size=${body.query?.size}&page=${body.query?.page}`,
        method: "GET",
      }),
    }),
  }),
});
