import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.min.css";
import { Provider } from "react-redux";

import App from "./App";
import "./styles/index.scss";
import { DrawerProvider } from "./context/Drawer";
import { store } from "./store/index";
import { AuthProvider } from "./context/Auth";

// Application  Root
const _AppRoot = (
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <DrawerProvider>
            <App />
          </DrawerProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

const documentRoot = document.getElementById("root");

ReactDom.render(_AppRoot, documentRoot);
