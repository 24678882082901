import { Button, Divider, Table, Modal, Popover, Space, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { skillApi } from "../store/apis/skill";
import { PageHeader } from "antd";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { Form, Input, Radio } from "antd";

const Skills = () => {
  const [fetchList, { data, isLoading, error }] = skillApi.useLazyListQuery();
  const [addSkill, { data: addSkilldata }] = skillApi.useAddSkillMutation();
  const [disabelSkill, { data: disableData }] = skillApi.useDisableSkillMutation();
  const [updateSkill, { data: updateData }] = skillApi.useUpdateSkillMutation();
  const [modal1Visible, setModal1Visible] = useState(false);
  const [category, setCategory] = useState<any | string>(null);
  const [name, setName] = useState<null | string>(null);
  const [localeError, setLocaleError] = useState<null | string>(null);

  const [isUpdateAction, setIsUpdateAction] = useState<boolean>(false);
  const [updatedSkillId, setIsUpdateActionatedSkillId] = useState<null | string>("");

  const [form] = Form.useForm();

  const onFormLayoutChange = props => {
    console.log(props);
  };

  useEffect(() => {
    fetchList();
  }, [disableData, addSkilldata, updateData]);

  const handleAddSkill = () => {
    if (isUpdateAction) {
      console.log(updatedSkillId);

      updateSkill({ category, name, id: updatedSkillId });
      setModal1Visible(false);
      setIsUpdateAction(false);
      setIsUpdateActionatedSkillId(null);
      setName(null);
      setCategory(null);
      return;
    }

    if (!name && !category) {
      setLocaleError("name and category are required");
      return;
    }

    addSkill({
      category,
      name,
    });

    setName(null);
    setCategory(null);
    setModal1Visible(false);
  };

  const handleDisableSkill = id => {
    disabelSkill({
      id,
    });
  };

  const handleSkillUpdate = row => {
    setCategory(row.category);
    setName(row.name);
    setIsUpdateAction(true);
    setModal1Visible(true);
    setIsUpdateActionatedSkillId(row.id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "disabeled",
      dataIndex: "disabeled",
      key: "disabeled",
      render: function (id, row) {
        return <div> {id ? "yes" : "no"} </div>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleSkillUpdate(record)}>update</a>
          <Popconfirm
            title="Are you sure to delete this skill ?"
            onConfirm={() => handleDisableSkill(record.id)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Disable</a>
          </Popconfirm>
        </Space>
      ),
      width: 210,
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Skills"
        subTitle={`total skills is ${data?.data.skills.length}`}
        extra={[
          <Button key="1" type="primary" onClick={() => setModal1Visible(true)}>
            add Skill
          </Button>,
        ]}
      />
      <Divider />
      <Table dataSource={data?.data.skills} columns={columns} />
      <Modal
        title={isUpdateAction ? "Update Skill" : "Add Skill"}
        style={{ top: 20 }}
        visible={modal1Visible}
        onOk={handleAddSkill}
        onCancel={() => {
          setModal1Visible(false);
          setIsUpdateAction(false);
          setIsUpdateActionatedSkillId(null);
          setName(null);
          setCategory(null);
        }}
        okText="Submit"
      >
        <Form layout="vertical" form={form} initialValues={{ layout: "" }} onValuesChange={onFormLayoutChange}>
          <Form.Item label="category">
            <Input placeholder="enter category" onChange={e => setCategory(e.target.value)} value={category} />
          </Form.Item>
          <Form.Item label="name">
            <Input placeholder="enter name" onChange={e => setName(e.target.value)} value={name!} />
          </Form.Item>
          <div>{localeError && <div style={{ color: "red", textAlign: "center" }}> {localeError} </div>}</div>
        </Form>
      </Modal>
    </div>
  );
};

export default Skills;
