import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_URL } from "../../../constant/api";
import { MyBaseQueryFn } from "../apiBaseQueryFn";
import prepareHeaders from "../prepareHeaders";
import { listRes } from "./types";

export const skillApi = createApi({
  reducerPath: "skill-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL!}/media/skill`,
    prepareHeaders,
  }) as MyBaseQueryFn,
  tagTypes: ["Skill"],
  endpoints: builder => ({
    list: builder.query<listRes, void>({
      query: () => ({
        url: `/list`,
        method: "GET",
      }),
    }),
    addSkill: builder.mutation({
      query: body => ({
        url: "/add",
        body,
        method: "POST",
      }),
    }),
    updateSkill: builder.mutation({
      query: body => ({
        url: "/update",
        body,
        method: "PUT",
      }),
    }),
    disableSkill: builder.mutation({
      query: body => ({
        url: `/disable/${body.id}`,
        body,
        method: "PUT",
      }),
    }),
  }),
});
