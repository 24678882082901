import { createApi } from '@reduxjs/toolkit/query/react';

import { MyBaseQueryFn } from '../apiBaseQueryFn';
import getBaseQuery from '../baseQueryWithReauth';
import { likeReq, likeRes, deleteLikeRes, deleteLikeReq } from './types';

export const likeApi = createApi({
    reducerPath: 'like-api',
    baseQuery: getBaseQuery('/media/like') as MyBaseQueryFn,
    tagTypes: ['LIKE'],
    endpoints: (builder) => ({
        like: builder.mutation<likeRes, likeReq>({
            query: (body) => ({
                url: `/add/${body.id}`,
                body,
                method: 'PUT',
            }),
        }),
        deleteLike: builder.mutation<deleteLikeRes, deleteLikeReq>({
            query: (body) => ({
                url: `/delete/${body.id}`,
                body,
                method: 'DELETE',
            }),
        }),
    }),
});
