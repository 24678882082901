import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { RootState } from '..';

// const prepareHeaders = (
//     headers: Headers,
//     { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
// ): MaybePromise<Headers> => {
//     return new Promise(async (resolve, reject) => {
//         const { accessToken, refreshToken } = (getState() as RootState).auth;
//         if (!accessToken) return resolve(headers);

//         const decodedToken = jwtDecode<ITokenPayload>(accessToken);
//         if (Date.now() >= decodedToken?.exp! * 1000) {
//             console.log(`refresh token`);
//             const res = await (
//                 await fetch(`${API_URL}/auth/auth/accessToken`, {
//                     method: 'POST',
//                     body: JSON.stringify({ refreshToken }),
//                     headers: { 'Content-Type': 'application/json' },
//                 })
//             ).json();
//             if (!res.success) {
//                 setCurrentAuth(null);
//                 reject();
//                 return;
//             }
//             setCurrentAuthTokens({
//                 accessToken: res.data.accessToken,
//                 refreshToken: res.data.refreshToken,
//             });
//             headers.set('Authorization', `Bearer ${res.data.accessToken}`);
//         } else {
//             if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);
//         }
//         resolve(headers);
//     });
// };

const prepareHeaders = (
    headers: Headers,
    { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
): Headers => {
    const { accessToken } = (getState() as RootState).auth;
    if (!accessToken) return headers;
    headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
};

export default prepareHeaders;
