import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_URL } from "../../../constant/api";
import { MyBaseQueryFn } from "../apiBaseQueryFn";
import prepareHeaders from "../prepareHeaders";
import { listRes } from "./types";

export const UsersApi = createApi({
  reducerPath: "users-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL!}/auth/auth/`,
    prepareHeaders,
  }) as MyBaseQueryFn,
  tagTypes: ["Users"],
  endpoints: builder => ({
    usersList: builder.query<listRes, any>({
      query: body => ({
        url: `/list?page=${body.page}&limit=${body.limit}`,
        method: "GET",
      }),
    }),
  }),
});
